document.addEventListener('touchstart', ()=>{}, {passive: true});
// console.log($)

const setVh = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};
 
window.addEventListener('load', setVh);
window.addEventListener('resize', setVh);

var current_scrollY;

function modalOpen() {
	current_scrollY = $(window).scrollTop();
	console.log('app:'+current_scrollY)
	$('body').attr('data-modalActive', 'true');
	$('body').css({
		position: 'fixed',
		top: -1 * current_scrollY,
		zIndex: 10
	});
	$('html').prop({ scrollTop: 0 });
}

function modalClose() {
	$('body').removeClass('menu-open')
	$('body').attr('data-modalActive', 'false');
	$('body').css({
		position: 'relative',
		top: 'auto'
	});
	$('html').prop({ scrollTop: current_scrollY });
	return;
}

function modalToggle() {
	if($('body').attr('data-modalActive')=='true'){
		modalClose()
	}else{
		modalOpen()
	}
}

$(function(){
  $('.modal-open').on('click', function (e) {
    e.preventDefault();
    $($(this).attr('href')).fadeIn();
		if(!$('.modal__bg').length){
			$('body').append('<div class="modal__bg"></div>')
		}
		$('.modal__bg').fadeIn();
		modalOpen();
  });
  $('.modal-close, .modal__bg').on('click', function () {
		$('.modal').fadeOut();
		$('.modal__bg').fadeOut();
		setTimeout(() => {
			$('.modal__bg').remove();
		}, 500);
		modalClose();
  });

	$('.recipient-detail__lead').each(function(){
		$(this).css('height',$(this).height())
		$(this).addClass('texthide')
	})
	$('.js-recipient-lead-toggle').on('click',function(){
		$(this).prev().toggleClass('texthide')
	})

  $('.js-accordion').on('click',function(){
    $($(this).attr('data-target')).slideToggle()
    $($(this).attr('data-show')).toggle()
    var $accordionForm = $('.accordion-form-toggle')
    if($accordionForm.length){
      if($accordionForm.val() == 0){
        $accordionForm.val(1)
      }else{
        $accordionForm.val(0)
      }
    }
  })

	if($('.layout__mv').length){
		$(window).on('load scroll',function (){
			var scroll = $(this).scrollTop()
			if(scroll < $(window).height() / 2 ){
				$('.layout__mv').removeClass('nav-scrolled')
			}else{
				$('.layout__mv').addClass('nav-scrolled')
			}
			// if(scroll < $(window).height() ){
			// 	$('.layout__mv .layout__nav').css('top','-'+scroll+'px')
			// }else{
			// 	$('.layout__mv .layout__nav').css('top','0px')
			// }
		});
	}

	if($('.layout__about').length){
		$(window).on('load scroll',function (){
			var scroll = $(this).scrollTop()
			if(scroll < $(window).height() / 100 ){
				$('.layout__about').removeClass('nav-scrolled')
			}else{
				$('.layout__about').addClass('nav-scrolled')
			}
		});
	}

	$('.js-hamburger').on('click',function(){
		// modalToggle()
		if($('body').hasClass('menu-open')){
			$('body').removeClass('menu-open')
			modalClose()
		}else{
			$('body').addClass('menu-open')
			modalOpen()
		}
	})

	$('.js-scroll-top').on('click',function(){
		$('html, body').animate({scrollTop:0}, 'fast');
	});

	$(window).on('load scroll',function(){
		if($(this).scrollTop() > 200){
			$('.js-scroll-top').fadeIn(100)
		}else{
			$('.js-scroll-top').fadeOut(100)
		}
	})

  
	// $('.koko').on('click',function(){
	// 	$('html, body').animate({scrollTop:0}, 'fast');
	// });

	$(window).on('load scroll',function(){
		if($(this).scrollTop() > 200){
			$('.koko').fadeIn(100)
		}else{
			$('.koko').fadeOut(100)
		}
	})




  // $('.nav__link--haslower').on('click',function(){
  //   if($(this).hasClass('active')){
  //     $(this).removeClass('active')
  //   }else{
  //     $('.nav__link--haslower').each(function(){
  //       $(this).removeClass('active')
  //       if($(this).next().is(':visible')){
  //         $(this).next().slideToggle(200)
  //       }
  //     })
  //     $(this).addClass('active')
  //   }
  //   $(this).next().slideToggle(200)
  // })

  let timeout;
  $('.nav__link__dropdown').on('mouseenter', function () {
    // clearTimeout(timeout);
    // if($(this).hasClass('active')) {
    //   $('.nav__link--haslower').next().slideUp(200);
    //   $('.nav__link--haslower').removeClass('active');
    //   $(this).next().slideDown(200);
    // }
    $(this).addClass('nav-is-active');
  });

  $('.nav__link__dropdown').on('mouseleave', function () {
    // setTimeout(function(){
      // $('.nav__link--haslower').next().slideUp(200);
      // $('.nav__link--haslower').removeClass('active');
      $(".nav__link__dropdown").removeClass('nav-is-active');
    // },200);
  });


	function submitDisabled (){
    var $submitBtn = $('[type="submit"]');
        $submitBtn.removeClass('btn--disabled');
    $('form input,form textarea').each(function(){
      if($(this).attr('type') == 'checkbox' || $(this).attr('type') == 'radio'){
        if ($(this).hasClass('required')){
					var name =  $(this).attr('name')
					if(!$('input[name="'+ name +'"]:checked').length){
						$submitBtn.addClass('btn--disabled');
					}
        }
      }else{
        if ($(this).hasClass('required') && $(this).val() == ''){
          $submitBtn.addClass('btn--disabled');
        }
      }
      if($(this).hasClass('is-error')) {
        $submitBtn.addClass('btn--disabled');
      }
    })
	}

  $('[type="checkbox"]').on('change' ,function(){
    if ($(this).hasClass('required')){
      var name =  $(this).attr('name')
      if(!$('input[name="'+ name +'"]:checked').length){
        $(this).parents('.account-form__input').addClass('is-error')
        $(this).parents('.account-form__input').append('<p class="checkbox-error is-error">必須項目です。</p>');
      }else{
        $(this).parents('.account-form__input').removeClass('is-error')
        $(this).parents('.account-form__input').find('.checkbox-error').remove();
      }
    }
  })

  $('input,textarea').on('keyup blur change', function() {
		submitDisabled()
  });

  $('#accordion-show,#accordion-hide').on('click', function() {
    setTimeout(() => {
      submitDisabled()
    }, 100);
  });

  $(window).on('load', function() {
		submitDisabled()
  });

  function scrollFixedInit() {
    $('.scrollFixed').each(function(){
      $(this).removeAttr('style')
      $(this).css({
        // 'right': 'auto',
        // 'bottom': 'auto',
        'top':$(this).parent().offset().top,
        'left':$(this).parent().offset().left,
        'maxWidth':$(this).parent().width(),
        'position': 'fixed'
      });
      if($(this).hasClass('scrollFixed--bottom')){
        $(this).css({
          'top': 'auto',
          'bottom':0,
        });
      }
    })
  }

  $(window).on('scroll',function() {
    var $window = $(this)
    var scrollTop = $window.scrollTop()
    var windowH = $window.height()
    $('.scrollFixed').each(function(){
      // $(this).css('top',scrollTop);
      var $parent = $(this).parent()
      var ofsetTop = $parent.offset().top;
      var bottomGap = windowH - ofsetTop - $(this).height()
      if($(this).hasClass('scrollFixed--bottom')){
        if(scrollTop + windowH >= ofsetTop + $parent.outerHeight()){
          $(this).addClass('sticky')
        }else{
          $(this).removeClass('sticky')
        }
      }else{
        if(scrollTop - bottomGap >= ofsetTop + $parent.outerHeight() - windowH){
          $(this).addClass('sticky')
        }else{
          $(this).removeClass('sticky')
        }
      }
    })
  })


  $(window).on('load resize',function() {
    scrollFixedInit()
  })


  $('a[href^="#"]').not('.modal-toggle').click(function (e) {
		setTimeout(() => {
			var headerH = $('.nav').height()
      // if($(window).width() <= 960){
      //   var headerH = 59
      // }
			var speed = 500
			var href = $(this).attr("href")
			var target = $(href == "#" || href == "" ? 'html' : href)
			var position = target.offset().top
			$.when(
				$("html, body").animate({
					scrollTop: position - headerH
				}, speed, "swing"),
				e.preventDefault(),
			).done(function() {
				var diff = target.offset().top;
				if (diff !== position) {
					$("html, body").animate({
						scrollTop: diff - headerH
					}, 500, "swing")
				}
			})
		}, 100)
		return false
	})

  $('.js-tagsNav-toggle').on('click',function(){
    $(this).toggleClass('active')
    $(this).next().slideToggle()
  })
})

// Java Script for mySwiper  
$(function(){
	$('.toggle_title').click(function(){
    $('.magazine-tagsNav__list').toggleClass('magazine-tagsNav__hidden');
		$(this).toggleClass('selected');
	});
});

/*
// タブに対してクリックイベントを適用
const tabs = document.getElementsByClassName('tab');
for(let i = 0; i < tabs.length; i++) {
  tabs[i].addEventListener('click', tabSwitch, false);
}
// タブをクリックすると実行する関数
function tabSwitch(){
  // タブのclassの値を変更
  document.getElementsByClassName('is-active')[0].classList.remove('is-active');
  this.classList.add('is-active');
  // コンテンツのclassの値を変更
  document.getElementsByClassName('is-show')[0].classList.remove('is-show');
  const arrayTabs = Array.prototype.slice.call(tabs);
  const index = arrayTabs.indexOf(this);
  document.getElementsByClassName('panel')[index].classList.add('is-show');
};
*/

$(".pc .tab").on("click", function (e) {
  e.preventDefault();
  var index = $(".tab").index(this);

  if (index < 3) { // Bottom
    $(".pc .tab").eq(0).removeClass("is-active");
    $(".pc .tab").eq(1).removeClass("is-active");
    $(".pc .tab").eq(2).removeClass("is-active");
    
    $(".pc .tab").eq(index).addClass("is-active");

    $(".pc .panel").eq(0).removeClass("is-show");
    $(".pc .panel").eq(1).removeClass("is-show");
    $(".pc .panel").eq(2).removeClass("is-show");
    if ($(".pc .tab").eq(2).hasClass("is-active")) {
      $(".pc .toggle_img").eq(0).attr("src", "/img/magazine/glass-white.png");
    } else {
      $(".pc .toggle_img").eq(0).attr("src", "/img/magazine/glass.png");
    }
    
  } else { // Top

    $(".pc .tab").eq(3).removeClass("is-active");
    $(".pc .tab").eq(4).removeClass("is-active");
    $(".pc .tab").eq(5).removeClass("is-active");

    $(".pc .tab").eq(index).addClass("is-active");
    
    $(".pc .panel").eq(3).removeClass("is-show");
    $(".pc .panel").eq(4).removeClass("is-show");
    $(".pc .panel").eq(5).removeClass("is-show");
    
    if ($(".pc .tab").eq(5).hasClass("is-active")) {
      $(".pc .toggle_img").eq(1).attr("src", "/img/magazine/glass-white.png");
    } else {
      $(".pc .toggle_img").eq(1).attr("src", "/img/magazine/glass.png");
    }
    
  }
  $(".pc .panel").eq(index).addClass("is-show");
});

$(".pc .tab-back").on("click", function (e) {
  e.preventDefault();
  var index = $(".tab-back").index(this);

  if (index < 3) { // Bottom
    $(".pc .tab-back").eq(0).removeClass("is-active");
    $(".pc .tab-back").eq(1).removeClass("is-active");
    $(".pc .tab-back").eq(2).removeClass("is-active");
    
    $(".pc .tab-back").eq(index).addClass("is-active");

    $(".pc .panel").eq(0).removeClass("is-show");
    $(".pc .panel").eq(1).removeClass("is-show");
    $(".pc .panel").eq(2).removeClass("is-show");
    
  } else { // Top

    $(".pc .tab").eq(3).removeClass("is-active");
    $(".pc .tab").eq(4).removeClass("is-active");
    $(".pc .tab").eq(5).removeClass("is-active");

    $(".pc .tab").eq(index).addClass("is-active");
    
    $(".pc .panel").eq(3).removeClass("is-show");
    $(".pc .panel").eq(4).removeClass("is-show");
    $(".pc .panel").eq(5).removeClass("is-show");

    
  }
  $(".pc .panel").eq(index).addClass("is-show");
});

// SPたぶ

// $(".sp .tab").on("click", function (e) {
//   e.preventDefault();
//   var index = $(".sp .tab").index(this);

//   if (index < 3) { // Bottom
//     $(".sp .tab").eq(0).removeClass("is-active");
//     $(".sp .tab").eq(1).removeClass("is-active");
//     $(".sp .tab").eq(2).removeClass("is-active");
    
//     $(".sp .tab").eq(index).addClass("is-active");

//     $(".sp .panel").eq(0).removeClass("is-show");
//     $(".sp .panel").eq(1).removeClass("is-show");
//     $(".sp .panel").eq(2).removeClass("is-show");
//     if ($(".sp .tab").eq(2).hasClass("is-active")) {
//       $(".sp .toggle_img").eq(0).attr("src", "/img/magazine/glass-white.png");
//     } else {
//       $(".sp .toggle_img").eq(0).attr("src", "/img/magazine/glass.png");
//     }
//   }
//   $(".sp .panel").eq(index).addClass("is-show");
// });

// SPたぶ
$(".sp .tab").on("click", function (e) {
  e.preventDefault();
  var index = $(".sp .tab").index(this);
  if($(this).hasClass('is-active')) {
    $(this).removeClass('is-active');
    $(".sp .panel").eq(index).removeClass("is-show");
    $(".sp .toggle_img").eq(0).attr("src", "/img/magazine/glass.png");
  }
  else {
    if (index < 3) { // Bottom
      $(".sp .tab").eq(0).removeClass("is-active");
      $(".sp .tab").eq(1).removeClass("is-active");
      $(".sp .tab").eq(2).removeClass("is-active");
      
      $(".sp .tab").eq(index).addClass("is-active");
  
      $(".sp .panel").eq(0).removeClass("is-show");
      $(".sp .panel").eq(1).removeClass("is-show");
      $(".sp .panel").eq(2).removeClass("is-show");
      if ($(".sp .tab").eq(2).hasClass("is-active")) {
        $(".sp .toggle_img").eq(0).attr("src", "/img/magazine/glass-white.png");
      } else {
        $(".sp .toggle_img").eq(0).attr("src", "/img/magazine/glass.png");
      }
    }
    $(".sp .panel").eq(index).addClass("is-show");
  }
});

$(function() {
  var navi = $('.magazine-tabscroll');    
  $(window).on('load scroll',function () {
      if ($(this).scrollTop() > 120) {
          navi.addClass('active');
      } else {
          navi.removeClass('active');
      }
  });
});

// 多重クリック防止
$('form').on('submit', function () {
  $(this).find(":submit").attr('disabled', 'disabled');
});